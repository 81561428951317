import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { getPartnerCountries } from "src/services/pmt-dashboard/countries-api";
import { overAllData } from "./data";

export const useFAQsMonitoring = () => {
  const [search, setSearch] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [yearWiseData, setYearWiseData] = useState([]);
  const [chartData, setChartData] = useState([]);

  // Fetch partner countries using React Query
  const { data: partnerCountries } = useQuery({
    queryKey: ["PartnerCountries"],
    queryFn: getPartnerCountries,
    staleTime: 10000,
  });

  // Format countries for filter options
  const formattedCountries =
    partnerCountries?.data?.map((country) => ({
      label: country.name,
      value: country.id,
    })) || [];

  // Function to handle year selection
  const getCountriesByYear = (selectedYear) => {
    const yearData = overAllData.find((data) => data.year === selectedYear);
    return yearData ? yearData.countries : [];
  };

  // Function to handle multiple country selection
  const getCountryData = (selectedYear, selectedCountry) => {
    const yearData = overAllData.find((data) => data.year === selectedYear);
    if (yearData) {
      // Filter countries that match the selected IDs
      const countryData = yearData.countries.filter(
        (country) => country.id === selectedCountry
      );
      return countryData;
    }
    return null;
  };

  // Apply filters to data
  useEffect(() => {
    if (search.year) {
      setFilteredData(getCountriesByYear(search.year));
    } else if (search.year && search.country) {
      setFilteredData(getCountryData(search.year, search.country));
    }
  }, [search]);

  const calculateColumnTotal = (key) => {
    return filteredData.reduce((sum, item) => sum + item[key], 0);
  };

  // Get all countries for the selected year
  console.log("year", getCountriesByYear(search.year));

  // Get data for a specific country under a selected year
  console.log(
    "year plus country",
    getCountryData(search.year, search.country)
  );

  return {
    setSearch,
    formattedCountries,
    filteredData,
    chartData,
    calculateColumnTotal,
  };
};
