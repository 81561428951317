import React from "react";
import CustomTabs from "src/components/PMTDashboard/common/custom.tabs";
import { programScopeTabs } from "../data";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const ScopeOfPrograms = () => {
  const navigate = useNavigate();

  return (
    <div>
      <h6 className="text-lg pb-2 font-semibold border-b border-gray-300 flex items-center gap-4">
        <FaArrowLeft
          className="cursor-pointer"
          onClick={() => navigate("/qualitative-assessments-latest")}
        />
        <span>Scope of all Programs</span>
      </h6>

      <div>
        <CustomTabs tabItems={programScopeTabs} />
      </div>
    </div>
  );
};

export default ScopeOfPrograms;
