import React from "react";
import { Chart } from "react-google-charts";

// Raw data with percentages
const rawData = [
  ["Country", "Sufficient space", "Insufficient space"],
  ["Mauritania", 100, 0],
  ["Niger", 100, 0],
  ["Sri Lanka", 95, 5],
  ["Malavi", 60, 40],
  ["Bangladesh", 0, 100],
];

// Function to prepare data with tooltips
function prepareDataWithTooltips(data) {
  const [header, ...rows] = data;

  // Extend the header to include tooltip columns
  const extendedHeader = [
    header[0], // Country
    header[1], // Sufficient space
    { role: "tooltip", type: "string" }, // Tooltip for Sufficient space
    header[2], // Insufficient space
    { role: "tooltip", type: "string" }, // Tooltip for Insufficient space
  ];

  // Process rows to normalize and add tooltips
  const processedRows = rows.map(([country, sufficient, insufficient]) => {
    return [
      country,
      sufficient / 100, // Normalized value for Sufficient space
      `${country}: Sufficient space ${sufficient}%`, // Tooltip for Sufficient space
      insufficient / 100, // Normalized value for Insufficient space
      `${country}:Insufficient space ${insufficient}% `, // Tooltip for Insufficient space
    ];
  });

  return [extendedHeader, ...processedRows];
}

export const options = {
  title: "Teachers on adequacy of school buildings for students (Country wise)",
  chartArea: { width: "50%" },
  isStacked: true,
  hAxis: {
    minValue: 0,
    maxValue: 1,
    format: "percent", 
  },
  tooltip: { isHtml: true }, 
};

export function HorizontalBarChart() {
  const processedData = prepareDataWithTooltips(rawData);

  return (
    <Chart
      chartType="BarChart"
      width="100%"
      height="400px"
      data={processedData}
      options={options}
      loader={<div>Loading Chart...</div>}
    />
  );
}
