import React from "react";
import { Chart } from "react-google-charts";

export function QuestionsPieCharts({ apiData }) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 p-4">
      {apiData.map((item, index) => {
        const { question, ...responses } = item; // Extract the question and responses
        const formattedData = [
          ["Response", "Count"],
          ...Object.entries(responses), // Convert response data into rows
        ];

        const options = {
          legend: "none", // Remove legend
          pieHole: 0.4, // Donut chart style
          sliceVisibilityThreshold: 0, // Ensure all slices are visible
          pieSliceText: "percentage", // Show percentages on slices
        };

        return (
          <div key={index} className="flex flex-col items-center">
            <Chart
              chartType="PieChart"
              data={formattedData}
              options={options}
              width={"200px"} // Fixed size for each chart
              height={"200px"}
            />
            <h4 className="text-center text-sm max-w-[170px]">{question}</h4>
          </div>
        );
      })}
    </div>
  );
}
