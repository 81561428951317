//const URL = process.env.REACT_APP_BASE_URL;
const URL = "https://backend.strategytracker.net/";

//const URL = "http://192.168.20.17:8000/";

const SECRET_KEY = "PMTEST123!";

export default URL;

export { SECRET_KEY };
