import React from "react";
import { CustomFilters } from "src/components/PMTDashboard/filters";
import { getFiltersData } from "./data";
import { useKeyFindings } from "./use-key-findings";
import { QuestionsPieCharts } from "src/components/assessment-charts/donut-chart";

const KeyFindings = (search) => {
  const { setSearch, formattedCountries } = useKeyFindings();

  console.log(search, "findings");

  const apiData = [
    {
      question:
        "Students have noticed an increase in enrolment over the past year",
      yes: 20,
      no: 10,
    },
    {
      question: "Are students satisfied with food?",
      satisfied: 10,
      notsatisfied: 2,
      neutral: 4,
    },
    {
      question: "Students are satisfied with safety protocols",
      satisfied: 10,
      notsatisfied: 2,
      neutral: 4,
    },
    {
      question: "Teachers recive online training sessions",
      yes: 30,
      no: 20,
      neutral: 25,
      none: 10,
    },
    {
      question: "Teachers are satisfied with the students grades",
      satisfied: 10,
      notsatisfied: 2,
      neutral: 4,
    },
    {
      question: "Teachers are aware of MH School Policies",
      yes: 50,
      no: 10,
    },
    {
      question: "Teachers recive online training sessions",
      yes: 30,
      no: 20,
      neutral: 25,
      none: 10,
    },
  ];

  return (
    <div>
      <div className="my-5 border border-gray-200 p-3 rounded-md flex items-center">
        <div>
          <p className="font-medium mb-1">Filters</p>
          {/* Global Custom Filters */}
          <CustomFilters
            onChanged={(e) => {
              setSearch(e);
            }}
            tableHeaderData={getFiltersData(formattedCountries)}
          />
        </div>
      </div>
      <div className="overflow-x-auto p-4">
        <h1 className="text-lg pb-2 font-semibold border-b border-gray-300 mb-8">
          Total No of Respondents Tool Wise
        </h1>
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-blue-500">
              <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                Principal
              </th>
              <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                Teacher
              </th>
              <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                Orphan
              </th>
              <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                Parents/Guardians
              </th>
              <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 px-4 py-2 text-center">
                20
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                15
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                10
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                4
              </td>
              <td className="border border-gray-300 px-4 py-2 text-center">
                39
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="container mx-auto">
        <QuestionsPieCharts apiData={apiData} />
      </div>
    </div>
  );
};

export default KeyFindings;
