import React from "react";
import { Chart } from "react-google-charts";

// Raw data with percentages for multiple bars
export const rawData = [
  [
    "Country",
    "No Security Surveillance",
    "No Entry Exit Record",
    "No Appropriate Boundary Wall",
    "Absence of Protocols",
  ],
  ["Afghanistan", 100, 0, 0, 0],
  ["Malawi", 100, 25, 25, 0],
  ["Senegal", 100, 95, 95, 79],
];

// Function to normalize data and prepare tooltips
function prepareDataWithTooltips(data) {
  const [header, ...rows] = data;

  // Extend the header to include tooltip columns for each bar
  const extendedHeader = [
    header[0],
    header[1],
    { role: "tooltip", type: "string" },
    header[2],
    { role: "tooltip", type: "string" },
    header[3],
    { role: "tooltip", type: "string" },
    header[4],
    { role: "tooltip", type: "string" },
  ];

  // Normalize rows to values between 0 and 1 and add tooltips
  const processedRows = rows.map(
    ([country, value1, value2, value3, value4]) => [
      country,
      value1 / 100,
      `Country: ${country}, No Security Surveillance: ${value1}%`,
      value2 / 100,
      `Country: ${country}, No Entry Exit Record: ${value2}%`,
      value3 / 100,
      `Country: ${country}, No Appropriate Boundary Wall: ${value3}%`,
      value4 / 100,
      `Country: ${country}, Absence of Protocols: ${value4}%`,
    ]
  );

  return [extendedHeader, ...processedRows];
}

export const options = {
  title: "Orphans unsatisfied reasons on safety protocols",
  chartArea: { width: "60%" },
  isStacked: false,
  hAxis: {
    // title: "Countries",
  },
  vAxis: {
    // title: "Percentage",
    minValue: 0,
    maxValue: 1,
    format: "percent", 
  },
  colors: ["#b0120a", "#ffab91", "#3366cc", "#109618"], 
};

export function VerticalMultiBarChart() {
  const processedData = prepareDataWithTooltips(rawData);

  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="400px"
      data={processedData}
      options={options}
      loader={<div>Loading Chart...</div>}
    />
  );
}
