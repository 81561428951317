import React from "react";
import { EnrolmentBarChart } from "src/components/assessment-charts/enrolment-barchart";

const Enrolment = (search) => {

  console.log(search, "findings");

  const data = [
    {
      title: "Students Reported Increase in Enrolment within a year (Country Wise)",
      data: [
        [
          "Element",
          "Count",
          { role: "style" },
          {
            role: "annotation",
            type: "string",
          },
        ],
        ["Malawi", 32, "green", "32%"],
        ["Niger", 10, "pink", "15%"],
        ["Mauritania", 9, "purple", "9%"],
        ["Mali", 20, "orange", "12%"],
        ["Afghanistan", 20, "margenda", "20%"],
        ["Bangladesh", 10, "indigo", "10%"],
        ["Srilanka", 17, "yellow", "17%"],
        ["Senegal", 30, "gray", "30%"],
      ],
    },
    {
        title: "Students reported increase in enrolment within a year (District wise)",
        data: [
          [
            "Element",
            "Count",
            { role: "style" },
            {
              role: "annotation",
              type: "string",
            },
          ],
          ["Malawi", 22, "green", "22%"],
          ["Niger", 10, "pink", "15%"],
          ["Mauritania", 9, "purple", "9%"],
          ["Mali", 20, "orange", "12%"],
          ["Afghanistan", 20, "margenda", "20%"],
          ["Bangladesh", 10, "indigo", "10%"],
          ["Srilanka", 17, "yellow", "17%"],
          ["Senegal", 20, "gray", "20%"],
        ],
      },
      {
        title: "Student enrolment with 5% increase (District-wise)",
        data: [
          [
            "Element",
            "Count",
            { role: "style" },
            {
              role: "annotation",
              type: "string",
            },
          ],
          ["Malawi", 32, "green", "32%"],
          ["Niger", 10, "pink", "15%"],
          ["Mauritania", 9, "purple", "9%"],
          ["Mali", 20, "orange", "12%"],
          ["Afghanistan", 20, "margenda", "20%"],
          ["Bangladesh", 10, "indigo", "10%"],
          ["Srilanka", 17, "yellow", "17%"],
          ["Senegal", 30, "gray", "30%"],
        ],
      },
      {
        title: "Student enrolment with 10% increase (District-wise)",
        data: [
          [
            "Element",
            "Count",
            { role: "style" },
            {
              role: "annotation",
              type: "string",
            },
          ],
          ["Malawi", 32, "green", "32%"],
          ["Niger", 10, "pink", "15%"],
          ["Mauritania", 9, "purple", "9%"],
          ["Mali", 20, "orange", "12%"],
          ["Afghanistan", 20, "margenda", "20%"],
          ["Bangladesh", 10, "indigo", "10%"],
          ["Srilanka", 17, "yellow", "17%"],
          ["Senegal", 30, "gray", "30%"],
        ],
      },
      {
        title: "Student enrolment with 20% increase or more (District-wise)",
        data: [
          [
            "Element",
            "Count",
            { role: "style" },
            {
              role: "annotation",
              type: "string",
            },
          ],
          ["Malawi", 32, "green", "32%"],
          ["Niger", 10, "pink", "15%"],
          ["Mauritania", 9, "purple", "9%"],
          ["Mali", 20, "orange", "12%"],
          ["Afghanistan", 20, "margenda", "20%"],
          ["Bangladesh", 10, "indigo", "10%"],
          ["Srilanka", 17, "yellow", "17%"],
          ["Senegal", 30, "gray", "30%"],
        ],
      },
  ];

  return (
    <div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
          {data.map((item, index) => (
            <EnrolmentBarChart key={index} item={item} />
          ))}
        </div>
    </div>
  );
};

export default Enrolment;
