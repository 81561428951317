import React from "react";
import { EnrolmentBarChart } from "src/components/assessment-charts/enrolment-barchart";
import { HorizontalBarChart } from "src/components/assessment-charts/horizantal-barchart";
import { VerticalMultiBarChart } from "src/components/assessment-charts/vertical-multibarchart";

const Facilities = () => {
  const countryItem = {
    title:
      "Students satisfaction on learning facilities (Country Wise)",
    data: [
      [
        "Element",
        "Count",
        { role: "style" },
        {
          role: "annotation",
          type: "string",
        },
      ],
      ["Sri Lanka", 100, "green", "100%"],
      ["Afghanistan", 10, "pink", "10%"],
      ["Malavi", 6, "purple", "6%"],
      ["Niger", 5, "margenda", "5%"],
    ],
  };

  const districtItem = {
    title:
      "Students satisfaction on learning facilities (District Wise)",
    data: [
      [
        "Element",
        "Count",
        { role: "style" },
        {
          role: "annotation",
          type: "string",
        },
      ],
      ["Putalam", 100, "green", "100%"],
      ["Blantyre", 17, "pink", "17%"],
      ["District 4th of Kabul Muncipality", 10, "purple", "10%"],
      ["Commune 4", 5, "orange", "5%"],
    ],
  };
  return (
    <div>
      <HorizontalBarChart />
      <div className="flex">
        <EnrolmentBarChart item={countryItem} />
        <EnrolmentBarChart item={districtItem} />
      </div>
      <VerticalMultiBarChart />
    </div>
  );
};

export default Facilities;
