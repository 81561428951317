import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getPartnerCountries } from "src/services/pmt-dashboard/countries-api";

export const useKeyFindings = () => {
  // State for searching
  const [search, setSearch] = useState();

  // Get all countries from an API
  const { data: partnerCountries } = useQuery({
    queryKey: [`PartnerCountries`],
    queryFn: getPartnerCountries,
    staleTime: 10000,
  });

  // Transform partnerCountries data to match the filter options format
  const formattedCountries =
    partnerCountries?.data?.map((country) => ({
      label: country?.name,
      value: country?.id,
    })) || [];

  return {
    setSearch,
    formattedCountries,
  };
};
