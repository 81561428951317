import React from "react";
import { Chart } from "react-google-charts";

export function BarChart({ item }) {
  const options = {
    title: item.title,
    width: 600,
    height: 400,
    bar: { groupWidth: "65%" },
    legend: { position: "none" },
    vAxis: {
      viewWindow: {
        min: 1,
        max: 40,
      },
      ticks: [1, 5, 10, 15, 20, 25, 30, 35, 40],
    },
  };
  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="400px"
      data={item.data}
      options={options}
    />
  );
}
