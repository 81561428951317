import Scope from "./program-scope/tab-items/scope";
import Progress from "./program-scope/tab-items/progress";
import Report from "./program-scope/tab-items/report";
import FAqsMonitoring from "./program-scope/tab-items/faqs-monitoring";
import KeyFindings from "./educations-tabs/key-findings";
import EducationScope from "./educations-tabs/scope";
import Enrolment from "./educations-tabs/enrolment";
import Facilities from "./educations-tabs/facilities";
import AcademicOutput from "./educations-tabs/academic-output";
import PoliciesAndSystems from "./educations-tabs/policies-systems";

// filtersData.js
export const getFiltersData = (formattedCountries) => [
  {
    type: "select",
    FieldProps: {
      name: "year",
      label: "Year",
      placeholder: "Select Year",
    },
    options: [
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
      { label: "2022", value: "2022" },
      { label: "2023", value: "2023" },
      { label: "2024", value: "2024" },
      { label: "2025", value: "2025" },
    ],
  },
  {
    type: "select",
    FieldProps: {
      name: "countries",
      label: "Countries",
      placeholder: "Select Country",
    },
    options: formattedCountries || [],
  },
  {
    type: "select",
    FieldProps: {
      name: "area",
      label: "Thematic Area",
      placeholder: "Select Thematic Area",
    },
    options: [
      { value: 1, label: "Education" },
      { value: 2, label: "Emergencies" },
      { value: 3, label: "Food Security" },
      { value: 4, label: "Health" },
      { value: 5, label: "Livelihoods" },
      { value: 6, label: "WASH" },
    ],
  },
];

// Tab items 

export const assessmentTabItems = (search) => [
  {
    id: 1,
    title: "Key Findings",
    content: <KeyFindings search={search} />,
    resource: "PMTProposal",
  },
  {
    id: 2,
    title: "Scope",
    content: <EducationScope />,
    resource: "PMTProposal",
  },
  {
    id: 3,
    title: "Enrolment",
    content: <Enrolment />,
    resource: "PMTProposal",
  },
  {
    id: 4,
    title: "Facilities",
    content: <Facilities />,
    resource: "PMTProposal",
  },
  {
    id: 5,
    title: "Academic Output",
    content: <AcademicOutput />,
    resource: "PMTProposal",
  },
  {
    id: 6,
    title: "Teachers Skills",
    content: "Teachers Skills",
    resource: "PMTProposal",
  },
  {
    id: 7,
    title: "Online Learning IT",
    content: "Online Learning IT",
    resource: "PMTProposal",
  },
  {
    id: 8,
    title: "Career Counselling & Life Skills",
    content: "Career Counselling & Life Skills",
    resource: "PMTProposal",
  },
  {
    id: 9,
    title: "Policies & Systems",
    content: <PoliciesAndSystems />,
    resource: "PMTProposal",
  },
];

export const washTabItems = [
  {
    id: 1,
    title: "Key Findings",
    content: "Key Findings",
    resource: "PMTProposal",
  },
  {
    id: 2,
    title: "Scope",
    content: "Scope",
    resource: "PMTProposal",
  },
  {
    id: 3,
    title: "Sustainability",
    content: "Sustainability",
    resource: "PMTProposal",
  },
  {
    id: 4,
    title: "Community Ownership",
    content: "Community Ownership",
    resource: "PMTProposal",
  },
  {
    id: 5,
    title: "Water Quality",
    content: "Water Quality",
    resource: "PMTProposal",
  },
  {
    id: 6,
    title: "Sanitation",
    content: "Sanitation",
    resource: "PMTProposal",
  },
  {
    id: 7,
    title: "Hygiene",
    content: "Hygiene",
    resource: "PMTProposal",
  },
  {
    id: 8,
    title: "Accessability $ Standards",
    content: "Accessability $ Standards",
    resource: "PMTProposal",
  },
];

export const healthTabItems = [
  {
    id: 1,
    title: "Key Findings",
    content: "Key Findings",
    resource: "PMTProposal",
  },
  {
    id: 2,
    title: "Scope",
    content: "Scope",
    resource: "PMTProposal",
  },
  {
    id: 3,
    title: "Health Facilities",
    content: "Health Facilities",
    resource: "PMTProposal",
  },
  {
    id: 4,
    title: "Eye-Contaract",
    content: "Eye-Contaract",
    resource: "PMTProposal",
  },
  {
    id: 5,
    title: "Inclusivity",
    content: "Inclusivity",
    resource: "PMTProposal",
  },
];

export const emergenciesTabItems = [
  {
    id: 1,
    title: "Key Findings",
    content: "Key Findings",
    resource: "PMTProposal",
  },
  {
    id: 2,
    title: "Scope",
    content: "Scope",
    resource: "PMTProposal",
  },
  {
    id: 3,
    title: "Assistance",
    content: "Assistance",
    resource: "PMTProposal",
  },
  {
    id: 4,
    title: "Disaster Reduction",
    content: "Disaster Reduction",
    resource: "PMTProposal",
  },
];

export const foodSecurityTabItems = [
  {
    id: 1,
    title: "Key Findings",
    content: "Key Findings",
    resource: "PMTProposal",
  },
  {
    id: 2,
    title: "Scope",
    content: "Scope",
    resource: "PMTProposal",
  },
  {
    id: 3,
    title: "Food & Nutrition",
    content: "Food & Nutrition",
    resource: "PMTProposal",
  },
  {
    id: 4,
    title: "Management",
    content: "Management",
    resource: "PMTProposal",
  },
  {
    id: 5,
    title: "Inclusivity",
    content: "Inclusivity",
    resource: "PMTProposal",
  },
  {
    id: 6,
    title: "Farmers Assistance",
    content: "Farmers Assistance",
    resource: "PMTProposal",
  },
  {
    id: 7,
    title: "Food Components",
    content: "Food Components",
    resource: "PMTProposal",
  },
];

export const livelyhoodTabItems = [
  {
    id: 1,
    title: "Key Findings",
    content: "Key Findings",
    resource: "PMTProposal",
  },
  {
    id: 2,
    title: "Scope",
    content: "Scope",
    resource: "PMTProposal",
  },
  {
    id: 3,
    title: "Trainings",
    content: "Trainings",
    resource: "PMTProposal",
  },
  {
    id: 4,
    title: "Facilities",
    content: "Facilities",
    resource: "PMTProposal",
  },
  {
    id: 5,
    title: "Farming Practices",
    content: "Farming Practices",
    resource: "PMTProposal",
  },
];

export const programScopeTabs = [
  {
    id: 1,
    title: "Scope",
    content: <Scope />,
    resource: "PMTProposal",
  },
  {
    id: 2,
    title: "Progress",
    content: <Progress />,
    resource: "PMTProposal",
  },
  {
    id: 3,
    title: "Quarter-wise Report",
    content: <Report />,
    resource: "PMTProposal",
  },
  {
    id: 4,
    title: "Monitoring of QAs",
    content: <FAqsMonitoring />,
    resource: "PMTProposal",
  },
];