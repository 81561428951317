// filtersData.js
export const getFiltersData = (formattedCountries) => [
  {
    type: "select",
    FieldProps: {
      name: "year",
      label: "Year",
      placeholder: "Select Year",
    },
    options: [
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
      { label: "2022", value: "2022" },
      { label: "2023", value: "2023" },
      { label: "2024", value: "2024" },
      { label: "2025", value: "2025" },
    ],
  },
  {
    type: "select",
    FieldProps: {
      name: "countries",
      label: "Countries",
      placeholder: "Select Country",
    },
    options: formattedCountries || [],
  },
  {
    type: "select",
    FieldProps: {
      name: "area",
      label: "Thematic Area",
      placeholder: "Select Thematic Area",
    },
    options: [
      { value: 1, label: "Education" },
      { value: 2, label: "Emergencies" },
      { value: 3, label: "Food Security" },
      { value: 4, label: "Health" },
      { value: 5, label: "Livelihoods" },
      { value: 6, label: "WASH" },
    ],
  },
  {
    type: "select",
    FieldProps: {
      name: "tool",
      label: "Tool",
      placeholder: "Select Tool",
    },
    options: [
      { value: 1, label: "Orphan Tool" },
      { value: 2, label: "Parents Tool" },
      { value: 3, label: "Teachers Tool" },
      { value: 4, label: "Principal Tool" },
    ],
  },
  {
    type: "select",
    FieldProps: {
      name: "schedule",
      label: "Schedule",
      placeholder: "Select Schedule",
    },
    options: [
      { value: 1, label: "Quarter" },
      { value: 2, label: "Month" },
    ],
  },
];

export const QAData = [
  {
    country: "Bangladesh",
    totalAssessments: 10,
    assessmentsThisMonth: 4,
    assessmentsThisWeek: 2,
    assessmentsThisDay: 1,
  },
  {
    country: "Niger",
    totalAssessments: 10,
    assessmentsThisMonth: 7,
    assessmentsThisWeek: 5,
    assessmentsThisDay: 1,
  },
  {
    country: "Senegal",
    totalAssessments: 10,
    assessmentsThisMonth: 4,
    assessmentsThisWeek: 5,
    assessmentsThisDay: 1,
  },
  {
    country: "Malawi",
    totalAssessments: 10,
    assessmentsThisMonth: 3,
    assessmentsThisWeek: 10,
    assessmentsThisDay: 1,
  },
];

export const bardata = [
  ["City", "2010 Population", "2000 Population"],
  ["New York City, NY", 8175000, 8008000],
  ["Los Angeles, CA", 3792000, 3694000],
  ["Chicago, IL", 2695000, 2896000],
  ["Houston, TX", 2099000, 1953000],
  ["Philadelphia, PA", 1526000, 1517000],
];

export const overAllData = [
  {
    year: "2024",
    countries: [
      {
        id: 2,
        country: "Bangladesh",
        totalAssessments: 100,
        assessmentsCurrentMonth: 7,
        assessmentsCurrentWeek: 5,
        assessmentsCurrentDay: 1,
        quarterlyAssessments: {
          quarterOneAssessments: 7,
          QuarterTwoAssessments: 4,
          QuarterThreeAssessments: 9,
          QuarterFourAssessmenst: 6,
        },
        monthlyAssessments: [
          {
            month: "Jan",
            assessments: 3,
          },
          {
            month: "Feb",
            assessments: 6,
          },
          {
            month: "March",
            assessments: 3,
          },
          {
            month: "April",
            assessments: 3,
          },
          {
            month: "May",
            assessments: 3,
          },
        ],
        programs: [
          {
            id: 1,
            program: "Education",
            totalAssessments: 100,
            assessmentsCurrentMonth: 7,
            assessmentsCurrentWeek: 5,
            assessmentsCurrentDay: 1,
            quarterlyAssessments: {
              quarterOneAssessments: 7,
              QuarterTwoAssessments: 4,
              QuarterThreeAssessments: 9,
              QuarterFourAssessmenst: 6,
            },
            monthlyAssessments: [
              {
                month: "Jan",
                assessments: 3,
              },
              {
                month: "Feb",
                assessments: 6,
              },
              {
                month: "March",
                assessments: 3,
              },
              {
                month: "April",
                assessments: 3,
              },
              {
                month: "May",
                assessments: 3,
              },
            ],
            tools: [
              {
                id: 1,
                tool: "Orphan",
                totalAssessments: 100,
                assessmentsCurrentMonth: 7,
                assessmentsCurrentWeek: 5,
                assessmentsCurrentDay: 1,
                quarterlyAssessments: {
                  quarterOneAssessments: 7,
                  QuarterTwoAssessments: 4,
                  QuarterThreeAssessments: 9,
                  QuarterFourAssessmenst: 6,
                },
                monthlyAssessments: [
                  {
                    month: "Jan",
                    assessments: 3,
                  },
                  {
                    month: "Feb",
                    assessments: 6,
                  },
                  {
                    month: "March",
                    assessments: 3,
                  },
                  {
                    month: "April",
                    assessments: 3,
                  },
                  {
                    month: "May",
                    assessments: 3,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        country: "Gambia",
        totalAssessments: 10,
        assessmentsCurrentMonth: 7,
        assessmentsCurrentWeek: 5,
        assessmentsCurrentDay: 1,
        quarterlyAssessments: {
          quarterOneAssessments: 7,
          QuarterTwoAssessments: 4,
          QuarterThreeAssessments: 9,
          QuarterFourAssessmenst: 6,
        },
        monthlyAssessments: [
          {
            month: "Jan",
            assessments: 3,
          },
          {
            month: "Feb",
            assessments: 6,
          },
          {
            month: "March",
            assessments: 3,
          },
          {
            month: "April",
            assessments: 3,
          },
          {
            month: "May",
            assessments: 3,
          },
        ],
        programs: [
          {
            id: 2,
            program: "WASH",
            totalAssessments: 100,
            assessmentsCurrentMonth: 7,
            assessmentsCurrentWeek: 5,
            assessmentsCurrentDay: 1,
            quarterlyAssessments: {
              quarterOneAssessments: 7,
              QuarterTwoAssessments: 4,
              QuarterThreeAssessments: 9,
              QuarterFourAssessmenst: 6,
            },
            monthlyAssessments: [
              {
                month: "Jan",
                assessments: 3,
              },
              {
                month: "Feb",
                assessments: 6,
              },
              {
                month: "March",
                assessments: 3,
              },
              {
                month: "April",
                assessments: 3,
              },
              {
                month: "May",
                assessments: 3,
              },
            ],
            tools: [
              {
                id: 1,
                tool: "Orphan",
                totalAssessments: 100,
                assessmentsCurrentMonth: 7,
                assessmentsCurrentWeek: 5,
                assessmentsCurrentDay: 1,
                quarterlyAssessments: {
                  quarterOneAssessments: 7,
                  QuarterTwoAssessments: 4,
                  QuarterThreeAssessments: 9,
                  QuarterFourAssessmenst: 6,
                },
                monthlyAssessments: [
                  {
                    month: "Jan",
                    assessments: 3,
                  },
                  {
                    month: "Feb",
                    assessments: 6,
                  },
                  {
                    month: "March",
                    assessments: 3,
                  },
                  {
                    month: "April",
                    assessments: 3,
                  },
                  {
                    month: "May",
                    assessments: 3,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
