// filtersData.js
export const getFiltersData = (formattedCountries) => [
    {
      type: "select",
      FieldProps: {
        name: "year",
        label: "Year",
        placeholder: "Select Year",
      },
      options: [
        { label: "2020", value: "2020" },
        { label: "2021", value: "2021" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },
        { label: "2024", value: "2024" },
        { label: "2025", value: "2025" },
      ],
    },
    {
      type: "select",
      FieldProps: {
        isMulti: true,
        name: "countries",
        label: "Countries",
        placeholder: "Select Country",
      },
      options: formattedCountries || [],
    },
    {
      type: "select",
      FieldProps: {
        name: "area",
        label: "Thematic Area",
        placeholder: "Select Thematic Area",
      },
      options: [
        { value: 1, label: "Education" },
        { value: 2, label: "Emergencies" },
        { value: 3, label: "Food Security" },
        { value: 4, label: "Health" },
        { value: 5, label: "Livelihoods" },
        { value: 6, label: "WASH" },
      ],
    },
  ];