// filtersData.js
export const getFiltersData = (formattedCountries) => [
  {
    type: "select",
    FieldProps: {
      name: "tool",
      label: "Tool",
      placeholder: "Select Tool",
    },
    options: [
      { label: "Principal", value: "1" },
      { label: "Teachers", value: "2" },
      { label: "Orphan", value: "3" },
      { label: "Parents", value: "4" },
    ],
  },
  {
    type: "select",
    FieldProps: {
      name: "year",
      label: "Year",
      placeholder: "Select Year",
    },
    options: [
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
      { label: "2022", value: "2022" },
      { label: "2023", value: "2023" },
      { label: "2024", value: "2024" },
      { label: "2025", value: "2025" },
    ],
  },
  {
    type: "select",
    FieldProps: {
      name: "quarter",
      label: "Quarter",
      placeholder: "Select Quarter",
    },
    options: [
      { label: "Quater 1", value: "1" },
      { label: "Quarter 2", value: "2" },
      { label: "Quarter 3", value: "3" },
      { label: "Quarter 4", value: "4" },
      { label: "All", value: "5" },
    ],
  },
];
