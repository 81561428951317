import React from "react";
import { Chart } from "react-google-charts";

function PieChart({ data }) {
  const options = {
    title: data.title,
    pieSliceText: "percentage",
    legend: { position: "bottom" }, // Set legend position to bottom
    titleTextStyle: {
        fontSize: 16,
        bold: true,
        color: 'black', // Customize title color
      },
  };

  return (
    <Chart
      chartType="PieChart"
      data={data.data}
      options={options}
      width={"600px"} // Fixed size for each chart
      height={"400px"}
    />
  );
}

export default PieChart;
