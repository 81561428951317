// filtersData.js
export const getFiltersData = (formattedCountries) => [
    {
        type: "select",
        FieldProps: {
          name: "countries",
          label: "Country",
          placeholder: "Select Country",
        },
        options: formattedCountries || [],
      },
      {
        type: "select",
        FieldProps: {
          name: "districts",
          label: "District",
          placeholder: "Select District",
        },
        options: formattedCountries || [],
      },
  ];
  