import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import { IoSearchOutline } from "react-icons/io5";
import Select from "react-select";
import Datepicker from "react-tailwindcss-datepicker";

const getDefaultParams = ({ tableHeaderData }) => {
  const defaultParams = {};
  tableHeaderData.forEach((data) => {
    if (data.type === "select" || data.type === "search") {
      defaultParams[data.FieldProps.name] = data.FieldProps.isMulti ? [] : "";
    } else if (data.type === "date") {
      defaultParams[data.FieldProps.name] = data.FieldProps.asSingle
        ? null
        : { startDate: null, endDate: null };
    }
  });
  return defaultParams;
};

export function CustomFilters(props) {
  const {
    tableHeaderData,
    onChanged = () => {
      return null;
    },
    debounceTimeout = 1000,
    searchLabel,
    selectLabel,
    dateLabel,
    minWidth,
    showGrid = true,
    padding = "2px 0px",
    fontSize = "14px",
    placeHolderColor = "#9ca3af",
    disabledInput = false,
    disabledSelect = false,
    disabledDate = false,
  } = props;

  const [params, setParams] = useState(getDefaultParams({ tableHeaderData }));

  // Debounced function
  const debouncedOnChange = useCallback(debounce(onChanged, debounceTimeout), [
    debounceTimeout,
  ]);

  useEffect(() => {
    debouncedOnChange(params);
  }, [params, debouncedOnChange]);

  function changeHandler({ target: { name, value } }) {
    setParams((oldParams) => ({
      ...oldParams,
      [name]: value,
    }));
  }

  return (
    <div>
      <div
        className={`${
          showGrid &&
          "grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-5 mt-0"
        }`}
      >
        {tableHeaderData.map((data) => {
          if (data.type === "search") {
            return (
              <div key={data.FieldProps.name} className="flex flex-col">
                <label className="text-sm font-medium text-gray-700 mb-1">
                  {searchLabel}
                </label>
                <div
                  className={`relative ${
                    minWidth ? `w-[${minWidth}px]` : "w-full"
                  }`}
                >
                  <div className="absolute inset-y-0 end-0 flex items-center pe-3.5 pointer-events-none">
                    <IoSearchOutline />
                  </div>
                  <input
                    disabled={disabledInput}
                    type="text"
                    name={data.FieldProps.name}
                    id={data.FieldProps.name}
                    className="border border-gray-300 text-gray-900 text-sm rounded focus:border-[1px] focus:border-[#26BBDD] focus:border-z block w-full pe-10 p-2.5"
                    value={params[data.FieldProps.name]}
                    onChange={(e) => changeHandler(e, data.type)}
                    {...data.FieldProps}
                  />
                </div>
              </div>
            );
          } else if (data.type === "select") {
            const options =
              data?.options?.map(({ label, value }) => ({
                label,
                value,
              })) || []; // Initialize options as empty array

            const handleSelectChange = (selectedOption) => {
              const event = {
                target: {
                  name: data.FieldProps.name,
                  value: selectedOption
                    ? data.FieldProps.isMulti
                      ? selectedOption.map((opt) => opt.value)
                      : selectedOption.value
                    : data.FieldProps.isMulti
                    ? []
                    : "",
                },
              };
              changeHandler(event, data.type);
            };

            let selectedOption = null;
            if (data.FieldProps.isMulti) {
              selectedOption = options.filter((option) =>
                params[data.FieldProps.name]?.includes(option.value)
              );
            } else {
              selectedOption = options.find(
                (option) => option.value === params[data.FieldProps.name]
              );
            }

            return (
              <div key={data.FieldProps.name} className="flex flex-col">
                <label className="text-sm font-medium text-gray-700 mb-1">
                  {selectLabel}
                </label>
                <Select
                  isDisabled={disabledSelect}
                  isMulti={data.FieldProps.isMulti}
                  isSearchable
                  isClearable
                  name={data.FieldProps.name}
                  value={selectedOption}
                  onChange={handleSelectChange}
                  options={options}
                  placeholder={data.FieldProps.placeholder}
                  classNamePrefix="select-selection"
                  isLoading={data.FieldProps.isLoading}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: "1px solid #d1d5db",
                      boxShadow: data.error
                        ? "0 0 0 0.3px red"
                        : state.isFocused
                        ? "0 0 0 1.5px #26BBDD"
                        : null,
                      "&:hover": {
                        border: "1px solid #d1d5db",
                      },
                      padding: `${padding}`,
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      fontSize: "14px",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: `${fontSize}`,
                      color: `${placeHolderColor}`,
                    }),
                  }}
                  {...data.FieldProps}
                />
              </div>
            );
          } else if (data.type === "date") {
            return (
              <div key={data.FieldProps.name} className="flex flex-col">
                <label className="text-sm font-medium text-gray-700">
                  {dateLabel}
                </label>
                <Datepicker
                  disabled={disabledDate}
                  useRange={data.FieldProps.useRange}
                  asSingle={data.FieldProps.asSingle}
                  value={params[data.FieldProps.name]}
                  onChange={(value) =>
                    changeHandler({
                      target: { name: data.FieldProps.name, value },
                    })
                  }
                  inputClassName="w-full border focus:border-[1px] focus:border-[#26BBDD] border-gray-300 rounded p-2 focus:outline-none mt-1 placeholder:text-[14px]"
                  {...data.FieldProps}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}
