import { Navigate, useLocation } from "react-router-dom";
import React from "react";
import { useAuth } from "./AuthContext";

const RequireAuthGuard = ({ children }) => {
  const { authState } = useAuth();
  let location = useLocation();

  if (!authState.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  // If the user is authenticated and the email exists, only redirect to /profile if not already there
  if (
    authState.isAuthenticated &&
    !authState?.user?.email &&
    location.pathname !== "/profile"
  ) {
    return <Navigate to="/profile" replace />;
  }

  return children;
};

export default RequireAuthGuard;
