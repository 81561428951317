export const initialEdges = [
    {
        id: "1-2",
        source: "1",
        target: "2",
    },
    {
        id: "1-3",
        source: "1",
        target: "3",
    },
    {
        id: "2-4",
        source: "2",
        target: "4",
    },
    {
        id: "2-5",
        source: "2",
        target: "5",
    },
    {
        id: "2-6",
        source: "2",
        target: "6",
    },
    {
        id: "5-7",
        source: "5",
        target: "7",
    },
    {
        id: "5-8",
        source: "5",
        target: "8",
    },
];

export const initialNodes = [
    {
        id: "1",
        position: {x: 5, y: 100},
        data: {number: 10},
        type: "toolType",
    },
    {
        id: "2",
        position: {x: 300, y: 10},
        data: {year: 2024, number: 40},
        type: "year",
    },
    {
        id: "3",
        position: {x: 300, y: 200},
        data: {year: 2025, number: 5},
        type: "year",
    },
    {
        id: "4",
        position: {x: 600, y: 10},
        data: {quarter: 1, number: 10},
        type: "quarter",
    },
    {
        id: "5",
        position: {x: 600, y: 200},
        data: {quarter: 2, number: 4},
        type: "quarter",
    },
    {
        id: "6",
        position: {x: 600, y: 400},
        data: {quarter: 3, number: 0},
        type: "quarter",
    },
    {
        id: "7",
        position: {x: 900, y: 200},
        data: {country: "Malawi", number: 34},
        type: "country",
    },
    {
        id: "8",
        position: {x: 900, y: 400},
        data: {country: "Afghanistan", number: 23},
        type: "country",
    },
];