import React from "react";
import PieChart from "src/components/assessment-charts/pie-chart";

const PoliciesAndSystems = () => {
  const data = {
    title: "Policies & systems review",
    data: [
      ["Policies", "Percentage"],
      ["Policies reviewed and updated regularly", 38],
      ["Policies not updated and reviewed regularly", 63],
    ],
  };
  return (
    <div>
      <PieChart data={data} />
    </div>
  );
};

export default PoliciesAndSystems;
