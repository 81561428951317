import React from "react";
import { Chart } from "react-google-charts";

export function EnrolmentBarChart({ item }) {
  // Normalize the data so that the values are between 0 and 1
  const normalizedData = item.data.map((entry, index) => {
    if (index === 0) return entry; // Skip the header
    const normalizedValue = entry[1] / 100; // Convert the count (percentage) to a decimal value (e.g., 32 becomes 0.32)
    return [entry[0], normalizedValue, entry[2], entry[3]]; // Return the normalized data
  });

  const options = {
    title: item.title,
    width: 600,
    height: 400,
    bar: { groupWidth: "65%" },
    legend: { position: "none" },
    vAxis: {
      viewWindow: {
        min: 0, // Start at 0
        max: 1, // Max at 100% (scaled to 1)
      },
      format: "#%", // Display the vertical axis as percentages
    },
  };

  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="400px"
      data={normalizedData}
      options={options}
    />
  );
}
