import React from "react";
import { CustomFilters } from "src/components/PMTDashboard/filters";
import { getFiltersData } from "./data";
import { useScope } from "./use-scope";
import { BarChart } from "src/components/assessment-charts/bar-chart";

const Scope = (search) => {
  const { setSearch, formattedCountries } = useScope();

  console.log(search, "findings");

  const data = [
    {
      title: "Orphan Students",
      data: [
        [
          "Element",
          "Count",
          { role: "style" },
          {
            role: "annotation",
            type: "string",
          },
        ],
        ["Malawi", 32, "green", "32"],
        ["Niger", 10, "pink", "15"],
        ["Mauritania", 9, "purple", "9"],
        ["Mali", 20, "orange", "12"],
        ["Afghanistan", 20, "margenda", "20"],
        ["Bangladesh", 10, "indigo", "10"],
        ["Srilanka", 17, "yellow", "17"],
        ["Senegal", 30, "gray", "30"],
      ],
    },
    {
        title: "Principal",
        data: [
          [
            "Element",
            "Count",
            { role: "style" },
            {
              role: "annotation",
              type: "string",
            },
          ],
          ["Malawi", 22, "green", "22"],
          ["Niger", 10, "pink", "15"],
          ["Mauritania", 9, "purple", "9"],
          ["Mali", 20, "orange", "12"],
          ["Afghanistan", 20, "margenda", "20"],
          ["Bangladesh", 10, "indigo", "10"],
          ["Srilanka", 17, "yellow", "17"],
          ["Senegal", 20, "gray", "20"],
        ],
      },
      {
        title: "Teachers",
        data: [
          [
            "Element",
            "Count",
            { role: "style" },
            {
              role: "annotation",
              type: "string",
            },
          ],
          ["Malawi", 32, "green", "32"],
          ["Niger", 10, "pink", "15"],
          ["Mauritania", 9, "purple", "9"],
          ["Mali", 20, "orange", "12"],
          ["Afghanistan", 20, "margenda", "20"],
          ["Bangladesh", 10, "indigo", "10"],
          ["Srilanka", 17, "yellow", "17"],
          ["Senegal", 30, "gray", "30"],
        ],
      },
      {
        title: "Parents/Guardians",
        data: [
          [
            "Element",
            "Count",
            { role: "style" },
            {
              role: "annotation",
              type: "string",
            },
          ],
          ["Malawi", 32, "green", "32"],
          ["Niger", 10, "pink", "15"],
          ["Mauritania", 9, "purple", "9"],
          ["Mali", 20, "orange", "12"],
          ["Afghanistan", 20, "margenda", "20"],
          ["Bangladesh", 10, "indigo", "10"],
          ["Srilanka", 17, "yellow", "17"],
          ["Senegal", 30, "gray", "30"],
        ],
      },
  ];

  return (
    <div>
      <div className="my-5 border border-gray-200 p-3 rounded-md flex items-center">
        <div>
          <p className="font-medium mb-1">Filters</p>
          {/* Global Custom Filters */}
          <CustomFilters
            onChanged={(e) => {
              setSearch(e);
            }}
            tableHeaderData={getFiltersData(formattedCountries)}
          />
        </div>
      </div>
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2">
          {data.map((item, index) => (
            <BarChart key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Scope;
